import { Vue, Component } from "vue-property-decorator";

@Component
export default class Validation extends Vue {
  public required: Function = (v: string): boolean | string =>
    !!v || "Field required";
  public emailAddress: Function = (v: string): boolean | string =>
    /.+@.+\..+/.test(v) || "E-mail must be valid";
  public passwordStrength: Function = (v: string): boolean | string =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      v
    ) ||
    "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number and one special character";
}
