



































































































































































import {
  Component,
  Ref,
  PropSync,
  Watch,
  Emit,
  Prop
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import ApiService from "@/services/apiService";
import OrderModel from "@/models/orderModel";
import { VForm } from "@/types/vForm";
import UserTimesheetEntryModel from "@/models/userTimesheetEntryModel";
import { Guid } from "guid-typescript";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import moment from "moment";
import TimesheetEntryCreateDialog from "@/components/timesheetEntry/TimesheetEntryCreateDialog.vue";
import TimesheetEntryEditDialog from "@/components/timesheetEntry/TimesheetEntryEditDialog.vue";
import UserTimesheetEntryLookupModel from "@/models/userTimesheetEntryLookupModel";
import apiService from "@/services/apiService";
import UserTimesheetEntryQueryModel from "@/models/userTimesheetEntryQueryModel";
import UserTimesheetModel from "@/models/userTimesheetModel";
import UserModel from "@/models/userModel";

@Component({
  components: {
    SnackbarMessage,
    TimesheetEntryCreateDialog,
    TimesheetEntryEditDialog
  }
})
export default class DailyView extends Validation {
  @Prop() selectedDate!: moment.Moment;
  @Prop(String) filterFromDate!: string;
  @Prop(String) filterToDate!: string;
  @Prop(String) userId!: string;

  private user: UserModel = new UserModel();
  private search: string = "";
  private snackbar: boolean = false;
  private successMesssage: string = "";
  private color: string = "";
  private headers: any[] = [];
  private isMobile: boolean = false;
  private loading: boolean = false;
  private timesheetEntryDialogModel: UserTimesheetEntryModel | null = null;
  private userTimesheetEntryModel: UserTimesheetEntryModel | null = null;
  private userTimesheetModel: UserTimesheetModel | null = null;
  private timesheetEntryExists: boolean = false;
  private existingTimesheetEntryId: Guid = Guid.createEmpty();
  private createDialog: boolean = false;
  private editDialog: boolean = false;
  private userTimesheetEntries: UserTimesheetEntryModel[] = [];
  private tab: number = 0;
  private currentDay: number = 0;
  private days: any[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  get currentDayName(): string {
    return this.days[this.currentDay];
  }

  private async setTab(day: string) {
    this.loading = true;
    this.currentDay =
      day == "Monday"
        ? 0
        : day === "Tuesday"
        ? 1
        : day === "Wednesday"
        ? 2
        : day === "Thursday"
        ? 3
        : day === "Friday"
        ? 4
        : day === "Saturday"
        ? 5
        : 6;

    await this.getUserTimesheet();
    await this.getTimesheetEntries();
    this.headers = this.getHeaders();
    this.loading = false;
  }

  async created() {
    this.loading = true;
    this.setTab(this.days[0]);
    if (
      this.filterFromDate &&
      this.filterFromDate !== "Invalid date" &&
      this.filterToDate &&
      this.filterToDate !== "Invalid date"
    ) {
      await this.getUserTimesheet();

      if (!this.userTimesheetModel || !this.userTimesheetModel.userId) {
        if (this.userId == "00000000-0000-0000-0000-000000000000") {
          const userString: string | null = localStorage.getItem("user");
          if (userString) {
            this.user = JSON.parse(userString);
            this.userId = this.user.userId.toString();
          }
        }
        this.userTimesheetModel = new UserTimesheetModel();
        this.userTimesheetModel.userId = this.userId;
        let weekending = moment.utc(this.endOfWeek).toDate();
        let currentDate = moment.utc(this.startOfWeek).toDate();
        let isUtcWeekending = moment(this.endOfWeek).isDST();
        let isUtcCurrentDate = moment(this.startOfWeek).isDST();
        let wDate = this.endOfWeek;
        isUtcWeekending ? wDate.setHours(wDate.getHours() + 1) : wDate;
        let sDate = this.startOfWeek;
        isUtcCurrentDate ? sDate.setHours(sDate.getHours() + 1) : sDate;
        this.userTimesheetModel.weekendDate = wDate;
        this.userTimesheetModel.date = sDate;
        this.userTimesheetModel = await apiService.post(
          "userTimesheet",
          this.userTimesheetModel
        );
      }

      await this.getTimesheetEntries();
    }
    this.loading = false;
    // this.$on("checkTimesheetEntryExists", this.checkTimesheetEntryExists);
    this.$on("closeCreateDialog", this.closeCreateDialog);
    this.$on("closeEditDialog", this.closeEditDialog);
    this.$on("cancelCreateDialog", this.cancelCreateDialog);
  }

  private async getUserTimesheet() {
    this.userTimesheetModel = await apiService.getCurrentWeekTimesheet(
      "userTimesheet/getByWeek",
      this.filterFromDate,
      this.filterToDate
    );
  }

  private async getTimesheetEntries() {
    let model: UserTimesheetEntryQueryModel = new UserTimesheetEntryQueryModel();

    if (this.userTimesheetModel && this.userTimesheetModel.userTimesheetId) {
      model.userTimesheetId = this.userTimesheetModel.userTimesheetId.toString();
      model.date = this.dateToFilter;

      this.userTimesheetEntries = await apiService.getTimesheetEntriesByTimesheetAndDate(
        "userTimesheet/getUserTimesheetEntriesByTimesheetIdAndDate",
        model
      );

      this.userTimesheetEntries = this.filterTimesheetEntries(
        this.userTimesheetEntries
      );

      // this.userTimesheetEntries = this.userTimesheetEntries.filter(f =>
      //   moment(f.created).isBetween(
      //     moment(this.dateToFilter),
      //     moment(this.filterToDate)
      //       .add(1, "days")
      //       .add(-1, "seconds")
      //   )
      // );
    }
  }

  private filterTimesheetEntries(
    timesheetEntries: UserTimesheetEntryModel[]
  ): UserTimesheetEntryModel[] {
    if (this.days[this.currentDay]) {
      let day: string = this.days[this.currentDay];
      if (day === "Monday") {
        return timesheetEntries.filter(f => f.monday);
      } else if (day === "Tuesday") {
        return timesheetEntries.filter(f => f.tuesday);
      } else if (day === "Wednesday") {
        return timesheetEntries.filter(f => f.wednesday);
      } else if (day === "Thursday") {
        return timesheetEntries.filter(f => f.thursday);
      } else if (day === "Friday") {
        return timesheetEntries.filter(f => f.friday);
      } else if (day === "Saturday") {
        return timesheetEntries.filter(f => f.saturday);
      } else if (day === "Sunday") {
        return timesheetEntries.filter(f => f.sunday);
      }
    }
    return timesheetEntries;
  }

  private async onAddTimesheetEntry() {
    if (this.userId == "00000000-0000-0000-0000-000000000000") {
      const userString: string | null = localStorage.getItem("user");
      if (userString) {
        this.user = JSON.parse(userString);
        this.userId = this.user.userId.toString();
      }
    }
    this.createDialog = true;
  }

  private async onEditTimesheetEntry(timesheetEntry: UserTimesheetEntryModel) {
    this.timesheetEntryDialogModel = Object.assign({}, timesheetEntry);
    if (this.timesheetEntryDialogModel) {
      this.editDialog = true;
    }
  }

  private async closeCreateDialog() {
    this.createDialog = false;
    this.snackbar = true;
    this.successMesssage = "Successfully created timesheet entry.";
    this.color = "success";
    setTimeout(() => {
      this.snackbar = false;
    }, 4000);
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
    await this.getUserTimesheet();
    await this.getTimesheetEntries();
  }

  private cancelCreateDialog() {
    this.createDialog = false;
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
  }

  private async closeEditDialog() {
    this.editDialog = false;
    this.timesheetEntryDialogModel = null;
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
    await this.getUserTimesheet();
    await this.getTimesheetEntries();
  }

  // public async checkTimesheetEntryExists(orderId: string, payType: string) {
  //   let timesheetEntryLookupModel: UserTimesheetEntryLookupModel = new UserTimesheetEntryLookupModel();

  //   if (
  //     this.userTimesheetModel &&
  //     this.userTimesheetModel.userTimesheetId &&
  //     orderId &&
  //     payType
  //   ) {
  //     timesheetEntryLookupModel.userTimesheetId = this.userTimesheetModel.userTimesheetId.toString();
  //     timesheetEntryLookupModel.orderId = orderId;
  //     timesheetEntryLookupModel.payType = payType;
  //     timesheetEntryLookupModel.dayToFilter = this.days[this.currentDay];
  //     let result: UserTimesheetEntryModel = await apiService.checkTimesheetEntryExistsAsync(
  //       "userTimesheet/getExistingTimesheetEntry",
  //       timesheetEntryLookupModel
  //     );

  //     this.timesheetEntryExists =
  //       result.userTimesheetEntryId &&
  //       result.userTimesheetEntryId.toString() !==
  //         Guid.createEmpty().toString() &&
  //       result.orderId !== "" &&
  //       result.orderId.toString() !== Guid.createEmpty().toString() &&
  //       result.userTimesheetId !== "" &&
  //       result.userTimesheetId.toString() !== Guid.createEmpty().toString();

  //     if (
  //       result.userTimesheetEntryId &&
  //       result.userTimesheetEntryId.toString() !== Guid.createEmpty().toString()
  //     ) {
  //       this.existingTimesheetEntryId = result.userTimesheetEntryId;
  //     } else {
  //       this.existingTimesheetEntryId = Guid.createEmpty();
  //     }
  //   }
  // }

  private async deleteTimesheetEntry(item: UserTimesheetEntryModel) {
    if (item && item.userTimesheetEntryId) {
      if (confirm(`Are you sure you want to remove this timesheet entry ?`)) {
        try {
          await apiService.delete(
            "userTimesheet/deleteTimesheetEntry",
            item.userTimesheetEntryId,
            this.days[this.currentDay]
          );
          await this.getUserTimesheet();
          await this.getTimesheetEntries();
          this.snackbar = true;
          this.successMesssage = "Successfully deleted timesheet entry.";
          this.color = "success";
          setTimeout(() => {
            this.snackbar = false;
          }, 4000);
        } catch (error) {
          this.snackbar = true;
          this.successMesssage = error.toString();
          this.color = "error";
          setTimeout(() => {
            this.snackbar = false;
          }, 4000);
        }
      }
    }
  }

  private getHeaders() {
    let day: string = this.days[this.currentDay].toLowerCase();
    return [
      {
        text: "Job Number",
        value: "orderNumber",
        sortable: true
      },
      {
        text: "Pay Type",
        value: "payType",
        sortable: true
      },
      {
        text: "Hours",
        value: day,
        sortable: true
      },
      { text: "Actions", value: "actions", sortable: false, align: "center" }
    ];
  }

  private onResize() {
    if (window.innerWidth < 769) this.isMobile = true;
    else this.isMobile = false;

    this.headers = this.getHeaders();
  }

  private checkEdit(item: UserTimesheetEntryModel): boolean {
    return (
      moment(item.created).isBetween(
        this.currentWeekStartDate,
        this.currentWeekEndDate
      ) && item.status !== "Approved"
    );
  }

  private isApproved(item: UserTimesheetEntryModel): boolean {
    return item.status === "Approved";
  }

  get totalDaily(): number {
    let day: string = this.days[this.currentDay];
    let sum: number = 0;
    if (day) {
      if (this.userTimesheetEntries && this.userTimesheetEntries.length) {
        this.userTimesheetEntries.forEach(
          a =>
            (sum +=
              day === "Monday"
                ? a.monday
                  ? a.monday
                  : 0
                : day === "Tuesday"
                ? a.tuesday
                  ? a.tuesday
                  : 0
                : day === "Wednesday"
                ? a.wednesday
                  ? a.wednesday
                  : 0
                : day === "Thursday"
                ? a.thursday
                  ? a.thursday
                  : 0
                : day === "Friday"
                ? a.friday
                  ? a.friday
                  : 0
                : day === "Saturday"
                ? a.saturday
                  ? a.saturday
                  : 0
                : a.sunday
                ? a.sunday
                : 0)
        );
      }

      return sum;
    }

    return sum;
  }

  get currentDayLongString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(this.currentDay, "days")
      .format("DD MMM YYYY");
  }

  get mondayDateString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("DD-MM-YYYY");
  }

  get tuesdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(1, "days")
      .format("MMM DD");
  }

  get wednesdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(2, "days")
      .format("MMM DD");
  }

  get thursdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(3, "days")
      .format("MMM DD");
  }

  get fridayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(4, "days")
      .format("MMM DD");
  }

  get saturdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(5, "days")
      .format("MMM DD");
  }

  get sundayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(6, "days")
      .format("MMM DD");
  }

  get startOfWeek(): Date {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .toDate();
  }

  get endOfWeek(): Date {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .toDate();
  }

  get endOfWeekMoment(): moment.Moment | null {
    return moment(this.endOfWeek);
  }

  get endOfWeekString(): string {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .format("DD/MM/yyyy");
  }

  get startOfWeekString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("MMM DD");
  }

  get currentDayString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(this.currentDay, "days")
      .format("MMM DD");
  }

  get dateToFilter() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(this.currentDay, "days")
      .format("YYYY-MM-DD");
  }

  get currentWeekStartDate(): Date {
    return moment()
      .startOf("isoWeek")
      .toDate();
  }

  get currentWeekEndDate(): Date {
    return moment()
      .endOf("isoWeek")
      .toDate();
  }
}
