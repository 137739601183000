



























































































































































import {
  Component,
  Ref,
  PropSync,
  Watch,
  Emit,
  Prop
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import ApiService from "@/services/apiService";
import OrderModel from "@/models/orderModel";
import { VForm } from "@/types/vForm";
import UserTimesheetEntryModel from "@/models/userTimesheetEntryModel";
import { Guid } from "guid-typescript";
import SnackbarMessage from "@/components/SnackbarMessage.vue";

@Component({
  components: { SnackbarMessage }
})
export default class TimesheetEntryEditDialog extends Validation {
  @Prop(Object) private model!: UserTimesheetEntryModel;
  @Prop(Boolean) readonly timesheetEntryExists!: boolean;
  @Prop() readonly existingTimesheetEntryId!: Guid;
  @Prop(Boolean) readonly isWeekly!: boolean;
  @Prop(String) readonly day!: string;
  @Prop(String) readonly currentDayLongString!: string;

  private payTypes: string[] = [
    // "Salary",
    // "Salary Adjustment",
    "Hourly Rate",
    "Overtime 1",
    "Overtime 2",
    "Offshore Rate Weekday",
    "Offshore Rate Weekend"
    // "First Aid/Warden"
  ];

  private dialog: boolean = false;
  private orders: Array<OrderModel> = [];
  private snackbar: boolean = false;
  private successMesssage: string = "";
  private color: string = "";
  private dataChanged: boolean = false;
  private originalModel: UserTimesheetEntryModel = new UserTimesheetEntryModel();

  protected async created(): Promise<void> {
    this.dialog = true;
    this.orders = await ApiService.getAll("userTimesheet/getOrders");
    this.originalModel = Object.assign(this.originalModel, this.model);
  }

  @Watch("model", { immediate: false, deep: true })
  private onModelChanged(value: any, oldValue: any): void {
    this.dataChanged = true;

    if (
      this.model.orderId &&
      this.model.orderId.toString() !== Guid.createEmpty().toString() &&
      this.model.payType !== ""
    ) {
      this.$parent.$emit(
        "checkTimesheetEntryExists",
        this.model.orderId,
        this.model.payType,
        this.model.userTimesheetEntryId
      );
    }
  }

  get invalid(): boolean {
    return (
      !this.model.monday &&
      !this.model.tuesday &&
      !this.model.wednesday &&
      !this.model.thursday &&
      !this.model.friday &&
      !this.model.saturday &&
      !this.model.sunday
    );
  }

  private async onSave() {
    let isFormValid: boolean = (this.$refs.editForm as Vue & {
      validate: () => boolean;
    }).validate();
    if (!isFormValid) {
      return;
    }

    if (this.invalid) {
      return;
    }

    try {
      await ApiService.put(
        "userTimesheet/editUserTimesheetEntry",
        this.model.userTimesheetEntryId,
        this.model
      );
      this.$parent.$emit("closeEditDialog");
    } catch (error) {
      this.snackbar = true;
      this.successMesssage = error.toString();
      this.color = "error";
      setTimeout(() => {
        this.snackbar = false;
      }, 4000);
    }
  }

  private onClose() {
    this.$parent.$emit("closeEditDialog");
  }

  private customFilter(item: OrderModel, queryText: string, itemText: string) {
    const textOne = item.number.toLowerCase();
    const textThree = item.customerName.toLowerCase();
    const searchText = queryText.toLowerCase();

    return (
      textOne.indexOf(searchText) > -1 ||
      textThree.indexOf(searchText) > -1
    );
  }
}
