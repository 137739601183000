








































































import { Vue, Component, Prop } from "vue-property-decorator";
import WeekStartDatesModel from "../models/weekStartDatesModel";
import apiService from "../services/apiService";
import UserModel from "../models/userModel";
import { Guid } from "guid-typescript";
import moment from "moment";
import UserTimesheetEntryModel from "../models/userTimesheetEntryModel";
import UserTimesheetModel from "../models/userTimesheetModel";
import OrderModel from "../models/orderModel";
import TimesheetEntryCreateDialog from "@/components/timesheetEntry/TimesheetEntryCreateDialog.vue";
import TimesheetEntryEditDialog from "@/components/timesheetEntry/TimesheetEntryEditDialog.vue";
import SnackbarMessage from "@/components/SnackbarMessage.vue";

import WeeklyView from "@/components/timesheetEntry/WeeklyView.vue";
import DailyView from "@/components/timesheetEntry/DailyView.vue";

@Component({
  components: {
    TimesheetEntryCreateDialog,
    TimesheetEntryEditDialog,
    SnackbarMessage,
    WeeklyView,
    DailyView
  }
})
export default class Dashboard extends Vue {
  private availableDates: string[] = [];
  private user: UserModel = new UserModel();
  private fromDateMenu: boolean = false;
  private selectedDate: moment.Moment | null = null;
  private userTimesheetModel: UserTimesheetModel = new UserTimesheetModel();
  private loading: boolean = false;
  private search: string = "";
  private datesReady: boolean = false;

  private timesheetEntryDialogModel: UserTimesheetEntryModel | null = null;
  private snackbar: boolean = false;
  private successMesssage: string = "";
  private color: string = "";

  private tab = 0;
  private dailyKey = 0;
  private weeklyKey = 0;

  private checkEdit(item: UserTimesheetEntryModel): boolean {
    return moment(item.created).isBetween(this.startOfWeek, this.endOfWeek);
  }

  get today() {
    const today = new Date(Date.now());
    return today.toISOString();
  }

  private allowedDates(item: any) {
    return this.availableDates.indexOf(item) !== -1;
  }

  get fromDate(): string {
    return this.selectedDate && this.endOfWeekMoment
      ? `${moment(this.selectedDate).format("DD/MM/YYYY")} - ${moment(
          this.endOfWeekMoment
        ).format("DD/MM/YYYY")}`
      : "";
  }

  private async fromDateChanged(date: string) {
    this.weeklyKey++;
    this.dailyKey++;
    this.timesheetEntryDialogModel = null;
  }

  private onWeeklyCreateOrUpdate() {
    this.weeklyKey++;
  }

  private onDailyCreateOrUpdate() {
    this.dailyKey++;
  }

  async created() {
    let weekStartDates: WeekStartDatesModel[] = await apiService.getAllWeekStartDates();

    if (weekStartDates && weekStartDates.length) {
      this.availableDates = weekStartDates.map(m => m.weekStartDateString);
      this.selectedDate =
        weekStartDates[weekStartDates.length - 1].currentWeekStartDate;
    }

    this.datesReady = true;

    const userString: string | null = localStorage.getItem("user");
    if (userString) {
      this.user = JSON.parse(userString);
    }
  }

  get startOfWeek(): Date {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .toDate();
  }

  get endOfWeek(): Date {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .toDate();
  }

  get endOfWeekMoment(): moment.Moment | null {
    return moment(this.endOfWeek);
  }

  get endOfWeekString(): string {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .format("DD/MM/yyyy");
  }

  get startOfWeekString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("MMM DD");
  }

  get filterFromDate() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
  }

  get filterToDate() {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .format("YYYY-MM-DD");
  }
}
