export default class UserTimesheetEntryModel {
  userTimesheetId: string = "";
  date: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
