













































































































































import {
  Component,
  Ref,
  PropSync,
  Watch,
  Emit,
  Prop
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import ApiService from "@/services/apiService";
import OrderModel from "@/models/orderModel";
import { VForm } from "@/types/vForm";
import UserTimesheetEntryModel from "@/models/userTimesheetEntryModel";
import { Guid } from "guid-typescript";
import SnackbarMessage from "@/components/SnackbarMessage.vue";

@Component({
  components: { SnackbarMessage }
})
export default class UserTimesheetEntryCreateDialog extends Validation {
  @Prop(String) readonly timesheetId!: string;
  @Prop(Boolean) readonly timesheetEntryExists!: boolean;
  @Prop(Boolean) readonly isWeekly!: boolean;
  @Prop(String) readonly day!: string;
  @Prop(String) readonly currentDayLongString!: string;

  private payTypes: string[] = [
    // "Salary",
    // "Salary Adjustment",
    "Hourly Rate",
    "Overtime 1",
    "Overtime 2",
    "Offshore Rate Weekday",
    "Offshore Rate Weekend"
    // "First Aid/Warden"
  ];

  private dialog: boolean = false;
  private orders: Array<OrderModel> = [];
  private model: UserTimesheetEntryModel = new UserTimesheetEntryModel();
  private snackbar: boolean = false;
  private successMesssage: string = "";
  private color: string = "";

  // @Watch("model", { immediate: false, deep: true })
  // private modelChanged() {
  //   if (
  //     this.model.orderId &&
  //     this.model.orderId.toString() !== Guid.createEmpty().toString() &&
  //     this.model.payType !== ""
  //   ) {
  //     this.$parent.$emit(
  //       "checkTimesheetEntryExists",
  //       this.model.orderId,
  //       this.model.payType
  //     );
  //   }
  // }

  protected async created(): Promise<void> {
    this.dialog = true;
    this.orders = await ApiService.getAll("userTimesheet/getOrders");
    this.model.userTimesheetId = this.timesheetId;
  }

  private async onSave() {
    let isFormValid: boolean = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (!isFormValid) {
      return;
    }

    try {
      this.model.dayToFilter = this.day;
      await ApiService.post(
        "userTimesheet/createUserTimesheetEntry",
        this.model
      );
      this.$parent.$emit("closeCreateDialog");
    } catch (error) {
      this.snackbar = true;
      this.successMesssage = error.toString();
      this.color = "error";
      setTimeout(() => {
        this.snackbar = false;
      }, 4000);
    }
  }

  private onClose() {
    this.$parent.$emit("cancelCreateDialog");
  }

  private customFilter(item: OrderModel, queryText: string, itemText: string) {
    const textOne = item.number.toLowerCase();
    const textThree = item.customerName.toLowerCase();
    const searchText = queryText.toLowerCase();

    return (
      textOne.indexOf(searchText) > -1 ||
      textThree.indexOf(searchText) > -1
    );
  }
}
