





































import { Vue, Component, Prop } from "vue-property-decorator";
import UserModule from "@/store/userModule.ts";
import { getModule } from "vuex-module-decorators";

const userModule = getModule(UserModule);

@Component
export default class TheNavbar extends Vue {
  private isDropdownActive: boolean | null = null;

  private logout(): void {
    userModule.logout();
  }

  get userName() {
    return userModule.userName;
  }

  get userId() {
    return userModule.userId.toString();
  }
}
