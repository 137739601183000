









import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SnackbarMessage extends Vue {
  @Prop(Boolean) snackbar!: boolean;
  @Prop(Boolean) vertical!: boolean;
  @Prop(String) snackbarText!: string;
  @Prop(String) color!: string;
  @Prop(Number) timeout!: number;
}
