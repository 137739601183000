import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import ApiService from "@/services/apiService";
import UserTokenModel from "../models/userTokenModel";
import Store from "@/store/index";
import UserModel from "../models/userModel";
import apiService from "@/services/apiService";
import { Guid } from "guid-typescript";
import UserAuthModel from "@/models/userAuthModel";

@Module({ dynamic: true, store: Store, name: "user", namespaced: true })
export default class UserModule extends VuexModule {
  private token: UserTokenModel = new UserTokenModel();
  private user: UserModel = new UserModel();

  @Mutation
  public async setUserData(data: UserTokenModel) {
    if (data) {
      this.token = new UserTokenModel(data);
      localStorage.setItem("token", JSON.stringify(data));

      const user = await apiService.getUserData();
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  @Mutation
  public async setUserToken(data: UserTokenModel) {
    if (data) {
      this.token = new UserTokenModel(data);
      localStorage.setItem("token", JSON.stringify(data));
    }
  }

  @Mutation
  public clearUserData(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    location.reload();
  }

  @Action({ commit: "setUserData" })
  public async login(credentials: UserAuthModel): Promise<UserTokenModel> {
    return await ApiService.postLogin(credentials);
  }

  @Action({ commit: "setUserToken" })
  public async refreshToken(credentials: UserTokenModel) {
    return await ApiService.refreshToken(credentials);
  }

  @Action({ commit: "clearUserData" })
  public logout(): void {}

  get loggedIn(): boolean {
    return !!this.token.accessToken;
  }

  get userName(): string {
    const name = this.user.firstname + " " + this.user.lastname;
    return name;
  }

  get userId(): Guid {
    return this.user.userId;
  }
}
