

































































































































































































































































































































































































































































































































































import {
  Component,
  Ref,
  PropSync,
  Watch,
  Emit,
  Prop
} from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import ApiService from "@/services/apiService";
import OrderModel from "@/models/orderModel";
import { VForm } from "@/types/vForm";
import UserTimesheetEntryModel from "@/models/userTimesheetEntryModel";
import { Guid } from "guid-typescript";
import SnackbarMessage from "@/components/SnackbarMessage.vue";
import moment from "moment";
import TimesheetEntryCreateDialog from "@/components/timesheetEntry/TimesheetEntryCreateDialog.vue";
import TimesheetEntryEditDialog from "@/components/timesheetEntry/TimesheetEntryEditDialog.vue";
import UserTimesheetEntryLookupModel from "@/models/userTimesheetEntryLookupModel";
import apiService from "@/services/apiService";

@Component({
  components: {
    SnackbarMessage,
    TimesheetEntryCreateDialog,
    TimesheetEntryEditDialog
  }
})
export default class WeeklyView extends Validation {
  @Prop() selectedDate!: moment.Moment;
  @Prop(String) filterFromDate!: string;
  @Prop(String) filterToDate!: string;

  private search: string = "";
  private snackbar: boolean = false;
  private successMesssage: string = "";
  private color: string = "";
  private headers: any[] = [];
  private isMobile: boolean = false;
  private loading: boolean = false;
  private timesheetEntryDialogModel: UserTimesheetEntryModel | null = null;
  private userTimesheetModel: UserTimesheetEntryModel | null = null;
  private timesheetEntryExists: boolean = false;
  private existingTimesheetEntryId: Guid = Guid.createEmpty();
  private createDialog: boolean = false;
  private editDialog: boolean = false;
  private userTimesheetEntries: UserTimesheetEntryModel[] = [];
  private mondayHourlyRate: number = 0;
  private mondayOT1Rate: number = 0;
  private mondayOT2Rate: number = 0;
  private mondayOffshoreWeekdayRate: number = 0;
  private mondayOffshoreWeekendRate: number = 0;
  private tuesdayHourlyRate: number = 0;
  private tuesdayOT1Rate: number = 0;
  private tuesdayOT2Rate: number = 0;
  private tuesdayOffshoreWeekdayRate: number = 0;
  private tuesdayOffshoreWeekendRate: number = 0;
  private wednesdayHourlyRate: number = 0;
  private wednesdayOT1Rate: number = 0;
  private wednesdayOT2Rate: number = 0;
  private wednesdayOffshoreWeekdayRate: number = 0;
  private wednesdayOffshoreWeekendRate: number = 0;
  private thursdayHourlyRate: number = 0;
  private thursdayOT1Rate: number = 0;
  private thursdayOT2Rate: number = 0;
  private thursdayOffshoreWeekdayRate: number = 0;
  private thursdayOffshoreWeekendRate: number = 0;
  private fridayHourlyRate: number = 0;
  private fridayOT1Rate: number = 0;
  private fridayOT2Rate: number = 0;
  private fridayOffshoreWeekdayRate: number = 0;
  private fridayOffshoreWeekendRate: number = 0;
  private saturdayHourlyRate: number = 0;
  private saturdayOT1Rate: number = 0;
  private saturdayOT2Rate: number = 0;
  private saturdayOffshoreWeekdayRate: number = 0;
  private saturdayOffshoreWeekendRate: number = 0;
  private sundayHourlyRate: number = 0;
  private sundayOT1Rate: number = 0;
  private sundayOT2Rate: number = 0;
  private sundayOffshoreWeekdayRate: number = 0;
  private sundayOffshoreWeekendRate: number = 0;
  private weeklyHourlyRate: number = 0;
  private weeklyOT1Rate: number = 0;
  private weeklyOT2Rate: number = 0;
  private weeklyOffshoreWeekdayRate: number = 0;
  private weeklyOffshoreWeekendRate: number = 0;

  async created() {
    if (
      this.filterFromDate &&
      this.filterFromDate !== "Invalid Date" &&
      this.filterToDate &&
      this.filterToDate !== "Invalid Date"
    ) {
      await this.getUserTimesheet();
      await this.getTimesheetEntries();

      if (this.userTimesheetEntries && this.userTimesheetEntries.length) {
        //Monday
        this.mondayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.monday)
          .reduce((sum, current) => sum + current.monday!, 0);
        this.mondayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.monday)
          .reduce((sum, current) => sum + current.monday!, 0);
        this.mondayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.monday)
          .reduce((sum, current) => sum + current.monday!, 0);

        this.mondayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.monday)
          .reduce((sum, current) => sum + current.monday!, 0);

        this.mondayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.monday)
          .reduce((sum, current) => sum + current.monday!, 0);

        //Tuesday
        this.tuesdayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.tuesday)
          .reduce((sum, current) => sum + current.tuesday!, 0);
        this.tuesdayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.tuesday)
          .reduce((sum, current) => sum + current.tuesday!, 0);
        this.tuesdayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.tuesday)
          .reduce((sum, current) => sum + current.tuesday!, 0);
        this.tuesdayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.tuesday)
          .reduce((sum, current) => sum + current.tuesday!, 0);

        this.tuesdayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.tuesday)
          .reduce((sum, current) => sum + current.tuesday!, 0);

        //Wednesday
        this.wednesdayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.wednesday)
          .reduce((sum, current) => sum + current.wednesday!, 0);
        this.wednesdayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.wednesday)
          .reduce((sum, current) => sum + current.wednesday!, 0);
        this.wednesdayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.wednesday)
          .reduce((sum, current) => sum + current.wednesday!, 0);
        this.wednesdayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.wednesday)
          .reduce((sum, current) => sum + current.wednesday!, 0);

        this.wednesdayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.wednesday)
          .reduce((sum, current) => sum + current.wednesday!, 0);

        //Thursday
        this.thursdayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.thursday)
          .reduce((sum, current) => sum + current.thursday!, 0);
        this.thursdayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.thursday)
          .reduce((sum, current) => sum + current.thursday!, 0);
        this.thursdayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.thursday)
          .reduce((sum, current) => sum + current.thursday!, 0);
        this.thursdayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.thursday)
          .reduce((sum, current) => sum + current.thursday!, 0);

        this.thursdayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.thursday)
          .reduce((sum, current) => sum + current.thursday!, 0);

        //Friday
        this.fridayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.friday)
          .reduce((sum, current) => sum + current.friday!, 0);
        this.fridayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.friday)
          .reduce((sum, current) => sum + current.friday!, 0);
        this.fridayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.friday)
          .reduce((sum, current) => sum + current.friday!, 0);
        this.fridayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.friday)
          .reduce((sum, current) => sum + current.friday!, 0);

        this.fridayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.friday)
          .reduce((sum, current) => sum + current.friday!, 0);

        //Saturday
        this.saturdayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.saturday)
          .reduce((sum, current) => sum + current.saturday!, 0);
        this.saturdayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.saturday)
          .reduce((sum, current) => sum + current.saturday!, 0);
        this.saturdayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.saturday)
          .reduce((sum, current) => sum + current.saturday!, 0);
        this.saturdayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.saturday)
          .reduce((sum, current) => sum + current.saturday!, 0);

        this.saturdayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.saturday)
          .reduce((sum, current) => sum + current.saturday!, 0);

        //Sunday
        this.sundayHourlyRate = this.userTimesheetEntries
          .filter(f => f.payType === "Hourly Rate" && f.sunday)
          .reduce((sum, current) => sum + current.sunday!, 0);
        this.sundayOT1Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 1" && f.sunday)
          .reduce((sum, current) => sum + current.sunday!, 0);
        this.sundayOT2Rate = this.userTimesheetEntries
          .filter(f => f.payType === "Overtime 2" && f.sunday)
          .reduce((sum, current) => sum + current.sunday!, 0);
        this.sundayOffshoreWeekdayRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekday" && f.sunday)
          .reduce((sum, current) => sum + current.sunday!, 0);

        this.sundayOffshoreWeekendRate = this.userTimesheetEntries
          .filter(f => f.payType === "Offshore Rate Weekend" && f.sunday)
          .reduce((sum, current) => sum + current.sunday!, 0);

        this.weeklyHourlyRate =
          this.mondayHourlyRate +
          this.tuesdayHourlyRate +
          this.wednesdayHourlyRate +
          this.thursdayHourlyRate +
          this.fridayHourlyRate +
          this.saturdayHourlyRate +
          this.sundayHourlyRate;

        this.weeklyOT1Rate =
          this.mondayOT1Rate +
          this.tuesdayOT1Rate +
          this.wednesdayOT1Rate +
          this.thursdayOT1Rate +
          this.fridayOT1Rate +
          this.saturdayOT1Rate +
          this.sundayOT1Rate;

        this.weeklyOT2Rate =
          this.mondayOT2Rate +
          this.tuesdayOT2Rate +
          this.wednesdayOT2Rate +
          this.thursdayOT2Rate +
          this.fridayOT2Rate +
          this.saturdayOT2Rate +
          this.sundayOT2Rate;

        this.weeklyOffshoreWeekdayRate =
          this.mondayOffshoreWeekdayRate +
          this.tuesdayOffshoreWeekdayRate +
          this.wednesdayOffshoreWeekdayRate +
          this.thursdayOffshoreWeekdayRate +
          this.fridayOffshoreWeekdayRate +
          this.saturdayOffshoreWeekdayRate +
          this.sundayOffshoreWeekdayRate;

        this.weeklyOffshoreWeekendRate =
          this.mondayOffshoreWeekendRate +
          this.tuesdayOffshoreWeekendRate +
          this.wednesdayOffshoreWeekendRate +
          this.thursdayOffshoreWeekendRate +
          this.fridayOffshoreWeekendRate +
          this.saturdayOffshoreWeekendRate +
          this.sundayOffshoreWeekendRate;
      }
    }

    this.$on("checkTimesheetEntryExists", this.checkTimesheetEntryExists);
    this.$on("closeCreateDialog", this.closeCreateDialog);
    this.$on("closeEditDialog", this.closeEditDialog);
    this.$on("cancelCreateDialog", this.cancelCreateDialog);
  }

  private async getUserTimesheet() {
    this.userTimesheetModel = await apiService.getCurrentWeekTimesheet(
      "userTimesheet/getByWeek",
      this.filterFromDate,
      this.filterToDate
    );
  }

  private async getTimesheetEntries() {
    if (this.userTimesheetModel && this.userTimesheetModel.userTimesheetId) {
      this.userTimesheetEntries = await apiService.getTimesheetEntriesByTimesheet(
        "userTimesheet/getUserTimesheetEntriesByUserTimesheetId",
        this.userTimesheetModel.userTimesheetId.toString()
      );
    }
  }

  private async onAddTimesheetEntry() {
    this.createDialog = true;
  }

  private async onEditTimesheetEntry(timesheetEntry: UserTimesheetEntryModel) {
    this.timesheetEntryDialogModel = Object.assign({}, timesheetEntry);
    this.editDialog = true;
  }

  private async closeCreateDialog() {
    this.createDialog = false;
    this.snackbar = true;
    this.successMesssage = "Successfully created timesheet entry.";
    this.color = "success";
    setTimeout(() => {
      this.snackbar = false;
    }, 4000);
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
    await this.getUserTimesheet();
    await this.getTimesheetEntries();
  }

  private cancelCreateDialog() {
    this.createDialog = false;
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
  }

  private async closeEditDialog() {
    this.editDialog = false;
    this.timesheetEntryDialogModel = null;
    this.timesheetEntryExists = false;
    this.existingTimesheetEntryId = Guid.createEmpty();
    await this.getUserTimesheet();
    await this.getTimesheetEntries();
  }

  public async checkTimesheetEntryExists(orderId: string, payType: string) {
    let timesheetEntryLookupModel: UserTimesheetEntryLookupModel = new UserTimesheetEntryLookupModel();

    if (
      this.userTimesheetModel &&
      this.userTimesheetModel.userTimesheetId &&
      orderId &&
      payType
    ) {
      timesheetEntryLookupModel.userTimesheetId = this.userTimesheetModel.userTimesheetId.toString();
      timesheetEntryLookupModel.orderId = orderId;
      timesheetEntryLookupModel.payType = payType;
      let result: UserTimesheetEntryModel = await apiService.checkTimesheetEntryExistsAsync(
        "userTimesheet/getExistingTimesheetEntry",
        timesheetEntryLookupModel
      );

      this.timesheetEntryExists =
        result.userTimesheetEntryId &&
        result.userTimesheetEntryId.toString() !==
          Guid.createEmpty().toString() &&
        result.orderId !== "" &&
        result.orderId.toString() !== Guid.createEmpty().toString() &&
        result.userTimesheetId !== "" &&
        result.userTimesheetId.toString() !== Guid.createEmpty().toString();

      if (
        result.userTimesheetEntryId &&
        result.userTimesheetEntryId.toString() !== Guid.createEmpty().toString()
      ) {
        this.existingTimesheetEntryId = result.userTimesheetEntryId;
      } else {
        this.existingTimesheetEntryId = Guid.createEmpty();
      }
    }
  }

  private async deleteTimesheetEntry(item: UserTimesheetEntryModel) {
    if (item && item.userTimesheetEntryId) {
      if (
        confirm(
          "Are you sure you want to delete this timesheet entry? This operation cannot be undone."
        )
      ) {
        try {
          await apiService.delete(
            "userTimesheet/deleteTimesheetEntry",
            item.userTimesheetEntryId,
            "Monday"
          );
          this.$parent.$emit("refreshTimesheetModel");
          await this.getTimesheetEntries();
          this.snackbar = true;
          this.successMesssage = "Successfully deleted timesheet entry.";
          this.color = "success";
          setTimeout(() => {
            this.snackbar = false;
          }, 4000);
        } catch (error) {
          this.snackbar = true;
          this.successMesssage = error.toString();
          this.color = "error";
          setTimeout(() => {
            this.snackbar = false;
          }, 4000);
        }
      }
    }
  }

  private getHeaders() {
    return [
      {
        text: "Job Number",
        value: "orderNumber",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: "Pay Type",
        value: "payType",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Mon"
          : `Mon <p style='text-align:center; margin-bottom:0px;'>${this.startOfWeekString}</p> `,
        value: "monday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Tue"
          : `Tue <p style='text-align:center; margin-bottom:0px;'>${this.tuesdayDateString}</p> `,
        value: "tuesday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Wed"
          : `Wed <p style='text-align:center; margin-bottom:0px;'>${this.wednesdayDateString}</p> `,
        value: "wednesday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Thu"
          : `Thu <p style='text-align:center; margin-bottom:0px;'>${this.thursdayDateString}</p> `,
        value: "thursday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Fri"
          : `Fri <p style='text-align:center; margin-bottom:0px;'>${this.fridayDateString}</p> `,
        value: "friday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Sat"
          : `Sat <p style='text-align:center; margin-bottom:0px;'>${this.saturdayDateString}</p> `,
        value: "saturday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: this.isMobile
          ? "Sun"
          : `Sun <p style='text-align:center; margin-bottom:0px;'>${this.sundayDateString}</p> `,
        value: "sunday",
        sortable: true,
        align: "center",
        divider: true
      },
      {
        text: "Hours",
        value: "totalHours",
        sortable: true,
        align: "center",
        divider: true
      }
      //   { text: "Actions", value: "actions", sortable: false, align: "center" }
    ];
  }

  private onResize() {
    if (window.innerWidth < 769) this.isMobile = true;
    else this.isMobile = false;

    this.headers = this.getHeaders();
  }

  private checkEdit(item: UserTimesheetEntryModel): boolean {
    return moment(item.created).isBetween(this.startOfWeek, this.endOfWeek);
  }

  get mondayDateString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("DD-MM-YYYY");
  }

  get tuesdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(1, "days")
      .format("MMM DD");
  }

  get wednesdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(2, "days")
      .format("MMM DD");
  }

  get thursdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(3, "days")
      .format("MMM DD");
  }

  get fridayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(4, "days")
      .format("MMM DD");
  }

  get saturdayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(5, "days")
      .format("MMM DD");
  }

  get sundayDateString() {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .add(6, "days")
      .format("MMM DD");
  }

  get startOfWeek(): Date {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .toDate();
  }

  get endOfWeek(): Date {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .toDate();
  }

  get endOfWeekMoment(): moment.Moment | null {
    return moment(this.endOfWeek);
  }

  get endOfWeekString(): string {
    return moment(this.selectedDate)
      .endOf("isoWeek")
      .format("DD/MM/yyyy");
  }

  get startOfWeekString(): string {
    return moment(this.selectedDate)
      .startOf("isoWeek")
      .format("MMM DD");
  }
}
