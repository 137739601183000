import Vue from "vue";
// @ts-ignore
import App from "../src/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
var excel = require("vue-excel-export");
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule.ts";
import "@progress/kendo-ui";
import "@progress/kendo-theme-material";
import { WindowInstaller } from "@progress/kendo-window-vue-wrapper";
import { ButtonsInstaller } from "@progress/kendo-buttons-vue-wrapper";
import { DataSourceInstaller } from "@progress/kendo-datasource-vue-wrapper";
import { ChartInstaller } from "@progress/kendo-charts-vue-wrapper";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Editor } from "@progress/kendo-editor-vue-wrapper";
import { EditorTool } from "@progress/kendo-editor-vue-wrapper";
import { EditorInstaller } from "@progress/kendo-editor-vue-wrapper";
import { process } from "@progress/kendo-data-query";
import moment from "moment";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(EditorInstaller);
Vue.use(ButtonsInstaller);
Vue.use(ChartInstaller);
Vue.use(DataSourceInstaller);
Vue.use(WindowInstaller);
Vue.use(GridInstaller);
Vue.use(GridPlugin);
Vue.component("Grid", Grid);
Vue.component("grid-toolbar", GridToolbar);

Vue.filter("formatDate", function(value: string) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatNewLines", (value: string) => {
  if (!value) return "";
  return value.replace(/\\n/g, "\n");
});

new Vue({
  router,
  store,
  created() {
    const tokenString: string | null = localStorage.getItem("token");
    if (tokenString) {
      const token = JSON.parse(tokenString);
      const userModule = getModule(UserModule);
      userModule.setUserData(token);
    }
  },
  vuetify,
  render: h => h(App)
}).$mount("#app");
