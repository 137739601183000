














import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "../src/store/userModule";
import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import Dashboard from "../src/views/Dashboard.vue";
import TheNavbar from "../src/components/layout/TheNavbar.vue";

const userModule = getModule(UserModule);

@Component({
  components: { Dashboard, TheNavbar }
})
export default class App extends Vue {
  private drawer: boolean | null = null;

  get computedAuth() {
    return userModule.loggedIn;
  }
}
