import { Guid } from "guid-typescript";
export default class UserTimesheetEntryLookupModel {
  public orderId: string = "";
  public payType: string = "";
  public userTimesheetId: string = "";
  public dayToFilter: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
