import { Guid } from "guid-typescript";
import moment from "moment";

export default class UserTimesheetEntryModel {
  userTimesheetEntryId: Guid = Guid.createEmpty();
  userTimesheetId: string = "";
  orderId: string = "";
  monday?: number;
  tuesday?: number;
  wednesday?: number;
  thursday?: number;
  friday?: number;
  saturday?: number;
  sunday?: number;
  totalHours: number = 0;
  createdBy: string = "";
  modifiedBy: string = "";
  orderNumber: string = "";
  created: Date = new Date();
  payType: string = "";
  dayToFilter: string = "";
  status: string = "";
  engineerDescription: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
