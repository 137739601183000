import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#DB0032",
        secondary: "#242424",
        accent: "#61A0CE",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  options: {
    customProperties: true // Allows for colours to be used in component css with var(--v-primary-base) || var(--v-accent-lighten2) etc.
  },
  icons: {
    iconfont: "mdi" // default - only for display purposes
  }
});
