import { Guid } from "guid-typescript";

export default class UserModel {
  userId: Guid = Guid.createEmpty();
  firstname: string = "";
  lastname: string = "";
  role: string = "";
  emailAddress: string = "";
  password: string = "";
  roleDescription: string = "";
  enabled: boolean = false;
  jobTitle: string = "";
  employeeCode: string = "";
  resourceCode: string = "";
  gender: string = "";
  martialStatus: string = "";
  dateofBirth?: Date;
  startDate?: Date;
  endDate?: Date;
  leaver: boolean = false;
  address1: string = "";
  address2: string = "";
  town: string = "";
  city: string = "";
  country: string = "";
  postcode: string = "";
  department: string = "";
  location: string = "";
  nINumber: string = "";
  salaried: string = "";
  payMethod: string = "";
  accountNumber?: number;
  sortCode?: number;
  accountName: string = "";
  bankName: string = "";
  branchName: string = "";
  basic?: number;
  overtime1?: number;
  overtime2?: number;
  offshoreRateWeekday?: number;
  offshoreRateWeekend?: number;
  isMobileUser: boolean = false;
  isPortalUser: boolean = false;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
