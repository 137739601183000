import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
// @ts-ignore
import Dashboard from "../views/Dashboard.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/forgottenPassword",
    name: "forgotten-password",
    component: () => import("../views/ForgottenPassword.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/password-reset/:id",
    name: "password-reset",
    component: () => import("../views/PasswordReset.vue"),
    props: true
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    // @ts-ignore
    component: () => import("../views/Login.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, from: Route, next: Function) => {
  const isAuthenticated: string | null = localStorage.getItem("token");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
