import axios, { AxiosResponse } from "axios";
import { Guid } from "guid-typescript";
import UserModel from "@/models/userModel";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule.ts";
import UserTokenModel from "@/models/userTokenModel";
import UserPasswordResetRequestModel from "@/models/userPasswordResetRequestModel";
import mime from "mime-types";
import UserTimesheetEntryModel from "../models/userTimesheetEntryModel";
import UserTimesheetModel from "../models/userTimesheetModel";
import UserAuthModel from "@/models/userAuthModel";
import UserTimesheetLookupModel from "@/models/userTimesheetEntryLookupModel";

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://localhost:44354/api/"
      : "https://red-20.azurewebsites.net/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache"
  }
});

apiClient.interceptors.request.use(request => {
  const tokenString: string | null = localStorage.getItem("token");

  if (tokenString) {
    const token = JSON.parse(tokenString);

    if (token) {
      request.headers["Authorization"] = `Bearer ${token.accessToken}`;
    }
  }

  return request;
});

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response) {
      if (
        error.config.url != apiClient.defaults.baseURL + "auth/refreshtoken" &&
        error.response.status === 401 &&
        error.config &&
        !error.config._retry
      ) {
        const userModule = getModule(UserModule);

        try {
          const tokenString: string | null = localStorage.getItem("token");

          if (tokenString) {
            const token = JSON.parse(tokenString);

            await userModule.refreshToken(token);
          } else {
            return Promise.reject(error);
          }
        } catch {
          await userModule.logout();
          return Promise.reject(error);
        }

        error.config._retry = true;
        return apiClient(error.config);
      }

      return Promise.reject(error);
    }
  }
);

export default {
  getBaseURL() {
    return apiClient.defaults.baseURL;
  },
  async postLogin(credentials: UserAuthModel): Promise<UserTokenModel> {
    const response = await apiClient.post<UserTokenModel>(
      "auth/token",
      credentials
    );
    return response.data;
  },
  async refreshToken(credentials: UserTokenModel): Promise<UserTokenModel> {
    const response = await apiClient.post<UserTokenModel>(
      "auth/refreshToken",
      credentials
    );
    return response.data;
  },
  async sendPasswordReset(credentials: UserPasswordResetRequestModel) {
    return apiClient.post("user/sendpasswordreset", credentials);
  },
  async postResetPassword(id: Guid, password: string) {
    return apiClient.post("user/resetpassword/" + id, {
      password: password
    });
  },
  async updatePassword(id: Guid, password: string) {
    return apiClient.post("user/updatePassword/" + id, {
      password: password
    });
  },
  async getUserData(): Promise<UserModel> {
    const response = await apiClient.get("user/getuserdata");
    return response.data;
  },
  async get<T>(url: string, id: Guid): Promise<T> {
    const reponse = await apiClient.get(url + "/" + id);
    return reponse.data;
  },
  async put<T>(url: string, id: Guid, model: any): Promise<T> {
    const reponse = await apiClient.put<T>(url + "/" + id, model);
    return reponse.data;
  },
  async post<T>(url: string, model: any): Promise<T> {
    const response = await apiClient.post<T>(url, model);
    return response.data;
  },
  async delete(url: string, id: any, dayToDelete: string) {
    return apiClient.delete(url + "/" + id + "/" + dayToDelete);
  },
  async getAllWeekStartDates<T>(): Promise<Array<T>> {
    const response = await apiClient.get<Array<T>>(
      "userTimesheet/getAllWeekStartDates"
    );
    return response.data;
  },
  async getAll<T>(url: string): Promise<Array<T>> {
    const response = await apiClient.get<Array<T>>(url);
    return response.data;
  },
  async checkTimesheetEntryExistsAsync<UserTimesheetEntryModel>(
    url: string,
    model: UserTimesheetLookupModel
  ): Promise<UserTimesheetEntryModel> {
    const response = await apiClient.post(url, model);
    return response.data;
  },
  async getCurrentWeekTimesheet<UserTimesheetModel>(
    url: string,
    fromDate: any,
    toDate: any
  ): Promise<UserTimesheetModel> {
    const response = await apiClient.get(url + "/" + fromDate + "/" + toDate);
    return response.data;
  },
  async getTimesheetEntriesByTimesheet<UserTimesheetEntryModel>(
    url: string,
    id: string
  ): Promise<Array<UserTimesheetEntryModel>> {
    const response = await apiClient.get(url + "/" + id);
    return response.data;
  },
  async getTimesheetEntriesByTimesheetAndDate<UserTimesheetEntryModel>(
    url: string,
    model: any
  ): Promise<Array<UserTimesheetEntryModel>> {
    const response = await apiClient.post(url, model);
    return response.data;
  }
};
