import { Guid } from "guid-typescript";
import moment from "moment";

export default class UserTimesheetModel {
  userTimesheetId: Guid = Guid.createEmpty();
  userId: string = "";
  weekendDate: Date = new Date();
  resourceCode: string = "";
  location: string = "";
  overtimePeriod: string = "";
  overtimeYear: string = "";
  date: Date = new Date();
  hours: number = 0;
  mondayHours: number = 0;
  tuesdayHours: number = 0;
  wednesdayHours: number = 0;
  thursdayHours: number = 0;
  fridayHours: number = 0;
  saturdayHours: number = 0;
  sundayHours: number = 0;
  createdBy: string = "";
  modifiedBy: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
